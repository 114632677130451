var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-auto mr-auto p-2  md:p-6 bg-white rounded-lg mb-4 md:max-w-3/4"},[_c('h1',{staticClass:"font-bold text-lg text-center text-darkblue mb-3"},[_vm._v(" "+_vm._s(_vm.$route.params.mode == "new" ? "New User" : "Update User")+" ")]),_c('div',{staticClass:"text-center mt-1 mb-2"},[(_vm.loadingUser)?_c('font-awesome-icon',{class:'text-dark animate-spin text-base mr-1 ',attrs:{"title":"loading","icon":['fas', 'spinner']}}):_vm._e()],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.SubmitUser)}}},[_c('div',{staticClass:" justify-around flex flex-col md:flex-row"},[_c('div',{staticClass:"w-full md:w-5/12 mb-3 md:mb-6 "},[_c('label',{staticClass:"block md:text-xs",attrs:{"for":"FullName"}},[_vm._v("Full Name "),_c('span',{staticClass:"text-red-700"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|custom_text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.name),expression:"user.name"}],staticClass:"border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block",attrs:{"type":"text","name":"Full Name"},domProps:{"value":(_vm.user.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "name", $event.target.value)}}}),_c('span',{staticClass:"text-red-700 md:text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full md:w-5/12 mb-3 md:mb-6 "},[_c('label',{staticClass:"block md:text-xs",attrs:{"for":"Amount"}},[_vm._v("Phone Number "),_c('span',{staticClass:"text-red-700"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.phoneNumber),expression:"user.phoneNumber"}],staticClass:"border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block",attrs:{"type":"text","name":"Phone Number"},domProps:{"value":(_vm.user.phoneNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "phoneNumber", $event.target.value)}}}),_c('span',{staticClass:"text-red-700 md:text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"  justify-around flex flex-col md:flex-row"},[_c('div',{staticClass:"w-full md:w-5/12 mb-3 md:mb-6 "},[_c('label',{staticClass:"block md:text-xs",attrs:{"for":"Position"}},[_vm._v("Position "),_c('span',{staticClass:"text-red-700"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|custom_text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.position),expression:"user.position"}],staticClass:"border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block",attrs:{"type":"text","placeholder":"E.g Driver,Manager","name":"Position"},domProps:{"value":(_vm.user.position)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "position", $event.target.value)}}}),_c('span',{staticClass:"text-red-700 md:text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full md:w-5/12 mb-3 md:mb-6 "},[_c('label',{staticClass:"block md:text-xs",attrs:{"for":"Role"}},[_vm._v(" Role "),_c('span',{staticClass:"text-red-700"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('t-rich-select',{attrs:{"name":"Role","required":true,"placeholder":"Select User","classes":_vm.rich_select_default_component_classes,"fixedClasses":_vm.rich_select_component_classes,"options":_vm.roleOptions},model:{value:(_vm.user.roleId._id),callback:function ($$v) {_vm.$set(_vm.user.roleId, "_id", $$v)},expression:"user.roleId._id"}}),_c('span',{staticClass:"text-red-700 md:text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"  justify-around flex flex-col md:flex-row"},[_c('div',{staticClass:"w-full mb-3 md:mb-6  md:w-5/12 "},[_c('label',{staticClass:"block md:text-xs",attrs:{"for":"FullName"}},[_vm._v("Email "),_c('span',{staticClass:"text-red-700"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block",attrs:{"type":"email","name":"Email"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}}),_c('span',{staticClass:"text-red-700 md:text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full md:w-5/12 mb-3 md:mb-6 "},[_c('label',{staticClass:"block md:text-xs",attrs:{"for":"Amount"}},[_vm._v("Address ")]),_c('validation-provider',{attrs:{"rules":"custom_text|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.address),expression:"user.address"}],staticClass:"border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block",attrs:{"type":"text","name":"Address"},domProps:{"value":(_vm.user.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "address", $event.target.value)}}}),_c('span',{staticClass:"text-red-700 md:text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('button',{staticClass:"text-white block md:text-xs bg-darkblue hover:text-black hover:bg-green-400 pl-3 pt-2 pb-2 pr-3 ml-auto mr-auto rounded-full",attrs:{"type":"submit"}},[(_vm.loadingSpinner)?_c('svg',{staticClass:"inline animate-spin mr-2",attrs:{"width":"25","height":"25","viewBox":"0 0 50 50","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M48 25C48 12.2975 37.7025 2 25 2","stroke":"black","stroke-width":"4"}}),_c('path',{attrs:{"d":"M48 25C48 37.7025 37.7025 48 25 48","stroke":"white","stroke-width":"4"}}),_c('path',{attrs:{"d":"M2 25C2 12.2975 12.2975 2 25 2","stroke":"white","stroke-width":"4"}}),_c('path',{attrs:{"d":"M25 48C12.2975 48 2 37.7025 2 25","stroke":"#C9000C","stroke-width":"4"}})]):_vm._e(),_vm._v(" "+_vm._s(_vm.$route.params.mode == "new" ? "Create User" : "Save User")+" ")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }